import axios from 'axios';

export const httpRequest = async (url, method, token, data, URL_SERVER) =>
  await axios({
    method,
    url: `${URL_SERVER}${url}` + (method === "get" ? !url.includes("?") ? "/" : "" : ""),
    data: data ? data : null,
    headers: token
      ? {
        "Content-Type": "application/json",
        "authorization": `Bearer ${token}`
      }
      : null
  });