import * as c from "../../constants/ActionTypes";
const initialState = {
  name: "",
  email: "",
  description: "",
  phone: "",
  captcha: "",
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case c.CHANGE_VALUE_CONTACT:
      return {
        ...state,
        [action.field]: action.value
      };
    case c.CLEAR_FORM_CONTACT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
