import {CHANGE_VALUE_EMAIL_TEMPLATE, CLEAR_FORM_CHANGE_VALUE_EMAIL_TEMPLATE, FETCH_CHANGE_VALUE_TEMPLATE_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  _id: "",
  code: "",
  title: "",
  type: "",
  source: "",
  example: "",
  company: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // case CHANGE_VALUE_EMAIL_TEMPLATE:
    //   return {
    //     ...state,
    //     [action.field]: action.value
    //   };
    case FETCH_CHANGE_VALUE_TEMPLATE_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_CHANGE_VALUE_EMAIL_TEMPLATE:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
