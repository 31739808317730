import React, { Suspense, Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
// core components
import Header from "../../components/Header/Header.js";
import Footer from "../../components/FooterHome/Footer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Parallax from "../../components/Parallax/Parallax.js";
import Particles from 'react-tsparticles';
import styles from "../../material-kit/material-kit-react/views/landingPage";
import asyncComponent from '../../util/asyncComponent';
import '../../material-kit/scss/material-kit-react.scss';
// Sections for this page
// const ServicesSection = lazy(() => import("./Sections/ServicesSection"));
// import ServicesSection from "./Sections/ServicesSection.js";
/* wait 500 ms to render component */
const ServicesSection = asyncComponent(() => import('./Sections/ServicesSection'))
const ProductSection = asyncComponent(() => import("./Sections/ProductSection.js"));
const TeamSection = asyncComponent(() => import("./Sections/TeamSection.js"));
const InfoSection = asyncComponent(() => import("./Sections/InfoSection.js"));
const WorkSection = asyncComponent(() => import("./Sections/WorkSection.js"));

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const CompLandingPage = (props) => {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          color: "primary",
          height: 400,
        }}
        {...rest}
      />
      <Parallax
        filter
        className="imgBody"
        image={'assets/img/codytionFondo.jpg'}
      >
        <div className={classes.particlesBody}>
          <Particles
            id="tsparticles"
            options={{

              fpsLimit: 60,
              interactivity: {
                events: {
                  onClick: {
                    enable: true,
                    mode: "push",
                  },
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 5,
                    duration: 5,
                    opacity: 0.8,
                    size: 80,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 100,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 200,
                  enable: true,
                  opacity: 2,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 2,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 1000,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 5,
                },
              },
              detectRetina: true,
            }}
          />
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Suspense fallback={<div>Loading</div>}>
            <ServicesSection />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <ProductSection />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <TeamSection />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <InfoSection />
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <WorkSection />
          </Suspense>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch({
      type: "START_AUTH",
    });
  }
  render() {
    return <CompLandingPage />;
  }
}


export default connect()(LandingPage);