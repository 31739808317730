import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Contact from './contacts/Contact';
import ListContacts from './contacts/ListContacts';
import Employee from './employees/Employee';
import ListEmployees from './employees/ListEmployees';
import Service from './services/Service';
import ListServices from './services/ListServices';
import SelectLocations from './locations/SelectLocations';
import ListLocations from './locations/ListLocations';
import ListInventaries from './inventary/ListInventaries';
import SelectInventary from './inventary/SelectInventary';
import Inventary from './inventary/Inventary';
import Novelty from './news/novelty';
import ListNews from './news/ListNews';
import Location from './locations/Location';
import Events from './reservations/events';
import Reservation from './reservations/Reservation';
import ListReservations from './reservations/ListReservations';
import reservationCheckList from './reservations/reservationCheckList';
import { reducer as toastrReducer } from 'react-redux-toastr'
import Company from './companies/Company';
import ListCompany from './companies/ListCompany';
import User from './users/User';
import ListUsers from './users/ListUsers';
import FormContact from './publicContact/formContact';
import Dashboard from './Dashboard';
import ListSales from './sales/ListSales';
import SelectSale from './sales/SelectSale';
import Sale from './sales/Sale';
import EventsCalendar from './reservations/eventsCalendar';
import ListTransactions from './transactions/ListTransactions';
import ListTemplates from './emailTemplate/ListTemplates';
import EmailTemplate from './emailTemplate/EmailTemplate';
import Preview from './emailTemplate/Preview';
import TabTemplates from './emailTemplate/TabTemplates';
// eslint-disable-next-line import/no-anonymous-default-export
export default (history) => combineReducers({
  toastr: toastrReducer,
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  contact: Contact,
  listContacts: ListContacts,
  employee: Employee,
  listEmployees: ListEmployees,
  service: Service,
  listServices: ListServices,
  reservation: Reservation,
  listReservations: ListReservations,
  listLocations: ListLocations,
  location: Location,
  selectLocations: SelectLocations,
  listInventaries: ListInventaries,
  inventary: Inventary,
  selectInventary: SelectInventary,
  reservationCheckList: reservationCheckList,
  events: Events,
  company: Company,
  listCompany: ListCompany,
  user: User,
  listUsers: ListUsers,
  novelty: Novelty,
  listNews: ListNews,
  stadistics: Dashboard,
  formContact: FormContact,
  listSales: ListSales,
  selectSale: SelectSale,
  sale: Sale,
  listTransactions: ListTransactions,
  eventsCalendar: EventsCalendar,
  listTemplates: ListTemplates,
  emailTemplate: EmailTemplate,
  preview: Preview,
  tabTemplates: TabTemplates,
});
