import {CHANGE_VALUE_NEWS, CLEAR_FORM_NEWS, FETCH_NEWS_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  description: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_NEWS:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_NEWS_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_NEWS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
