import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from '../../util/CustomScrollbars';

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {

        const parentLiEle = that.closest(this, 'li');
        if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains('open')) {
            menuLi[i].classList.remove('open', 'active');
          } else {
            menuLi[i].classList.add('open', 'active');
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, 'li');
            if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
              menuLi[j].classList.remove('open');
            } else {
              if (menuLi[j].classList.contains('open')) {
                menuLi[j].classList.remove('open');
              } else {
                menuLi[j].classList.add('open');
              }
            }
          }
        }
      }
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    const { rol } = this.props.user;
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          {rol === 'admin' ? (
            <Fragment>
              <li className="menu no-arrow">
                <NavLink to="/app/home-page">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text"><IntlMessages id="pages.homePage" /> </span>
                </NavLink>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-time zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="transactions.title" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/transactions">
                      <span className="nav-text"><IntlMessages id="transactions.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-settings zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="setting.menu" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/location-page">
                      <span className="nav-text"><IntlMessages id="location.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="/app/service-page">
                      <span className="nav-text"><IntlMessages id="service.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="/app/employee-page">
                      <span className="nav-text"><IntlMessages id="employee.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="chat.contacts" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/contact-page">
                      <span className="nav-text"><IntlMessages id="contact.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-shopping-basket" />
                  <span className="nav-text">
                    <IntlMessages id="inventory.title" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/inventary-page">
                      <span className="nav-text"><IntlMessages id="inventory.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-shopping-cart-plus" />
                  <span className="nav-text">
                    <IntlMessages id="sale.title" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/sales-page">
                      <i className="zmdi zmdi-money" />
                      <span className="nav-text"><IntlMessages id="sale.money.income" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-calendar-alt zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="reservation.menu" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="reservation-page">
                      <span className="nav-text"><IntlMessages id="create.reservation.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="reservations">
                      <span className="nav-text"><IntlMessages id="create.list.reservation.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="booking-calendar">
                      <span className="nav-text"><IntlMessages id="calendar.reservation.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </Fragment>
          ) : rol === 'contact' ? (
            <li className="menu collapse-box">
              <Button>
                <i className="zmdi zmdi-calendar-alt zmdi-hc-fw" />
                <span className="nav-text">
                  <IntlMessages id="reservation.menu" />
                </span>
              </Button>
              <ul className="sub-menu">
                <li>
                  <NavLink className="prepend-icon" to="reservation-page">
                    <span className="nav-text"><IntlMessages id="create.reservation.menu" /></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="reservations">
                    <span className="nav-text"><IntlMessages id="create.list.reservation.menu" /></span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="prepend-icon" to="booking-calendar">
                    <span className="nav-text"><IntlMessages id="calendar.reservation.menu" /></span>
                  </NavLink>
                </li>
              </ul>
            </li>
          ) : rol === 'employee' ? (
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/home-page">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text"><IntlMessages id="pages.homePage" /> </span>
                </NavLink>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-accounts-add zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="chat.contacts" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/contact-page">
                      <span className="nav-text"><IntlMessages id="contact.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-calendar-alt zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="reservation.menu" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="reservation-page">
                      <span className="nav-text"><IntlMessages id="create.reservation.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="reservations">
                      <span className="nav-text"><IntlMessages id="create.list.reservation.menu" /></span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="prepend-icon" to="booking-calendar">
                      <span className="nav-text"><IntlMessages id="calendar.reservation.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <>
              <li className="menu no-arrow">
                <NavLink to="/app/home-page">
                  <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
                  <span className="nav-text"><IntlMessages id="pages.homePage" /> </span>
                </NavLink>
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-settings zmdi-hc-fw" />
                  <span className="nav-text">
                    <IntlMessages id="admin.admins" />
                  </span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/company-page">
                      <span className="nav-text"><IntlMessages id="company.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/user-page">
                      <span className="nav-text"><IntlMessages id="user.menu" /></span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
