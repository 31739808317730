import {
  FETCH_FILE_VALUE_FILES_SUCCESS,
  CHANGE_VALUE_INVENTARY,
  CLEAR_FORM_INVENTARY,
  FETCH_INVENTARY_SUCCESS,
  FETCH_ALL_NEWS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  name: "",
  category: "",
  quantity: "",
  mark: "",
  price: 0,
  files: [],
  description: "",
  expiration_date: "",
  sku: "",
  image: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_INVENTARY:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_FILE_VALUE_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload
      };
    case FETCH_INVENTARY_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_INVENTARY:
      return {
        ...INIT_STATE
      };
    case FETCH_ALL_NEWS_SUCCESS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
