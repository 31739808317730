import { SIGNOUT_USER_SUCCESS, FETCH_ALL_COMPANY_SUCCESS, FETCH_ALL_COMPANY_RESET } from "../../constants/ActionTypes";

const INIT_STATE = {
  records: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_COMPANY_SUCCESS:
      const data = action.payload;
      return { ...state, records: data };
    case FETCH_ALL_COMPANY_RESET:
      return {
        ...INIT_STATE
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}