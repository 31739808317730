import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET,
  URL_SERVER,
  NO_TOKEN,
  USER_DATA
} from "../constants/ActionTypes";
import { httpRequest } from "../util/Api";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({ name, email, password }) => {
  //   return (dispatch) => {
  //     dispatch({type: FETCH_START});
  //     axios.post('auth/register', {
  //         email: email,
  //         password: password,
  //         name: name
  //       }
  //     ).then(({data}) => {
  //       console.log("data:", data);
  //       if (data.result) {
  //         localStorage.setItem("token", JSON.stringify(data.token.access_token));
  //         axios.defaults.headers.common['Authorization'] = "Bearer " + data.token.access_token;
  //         dispatch({type: FETCH_SUCCESS});
  //         dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
  //         dispatch({type: USER_DATA, payload: data.user});
  //       } else {
  //         console.log("payload: data.error", data.error);
  //         dispatch({type: FETCH_ERROR, payload: "Network Error"});
  //       }
  //     }).catch(function (error) {
  //       dispatch({type: FETCH_ERROR, payload: error.message});
  //       console.log("Error****:", error.message);
  //     });
  //   }
};

export function validateSession() {
  return validateToken(async (dispatch, token) => {
    if (token) {
      try {
        const { data } = await httpRequest(
          "/user/check-sesion",
          "post",
          token,
          null,
          URL_SERVER
        );
        if (data && data.user) {
          localStorage.setItem("user", JSON.stringify(data.user));
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      } catch (error) {
        solveError(
          error.response.data||error?.response?.data?.error || error.message ||'',
          dispatch,
          FETCH_ERROR
        );
      }
    }
  });
}
export const userSignIn = ({ email, password}) => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_START });
      const user = {
        email: email,
        password: password
      }
      const { data } = await httpRequest(
        "/login",
        "post",
        null,
        user,
        URL_SERVER
      );

      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
        dispatch({ type: USER_DATA, payload: data.user });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  }
};

export const acceptTerms = (user) => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await httpRequest(
        "/acceptTerms",
        "post",
        null,
        {user},
        URL_SERVER
      );

      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
        dispatch({ type: USER_DATA, payload: data });
      } else {
        dispatch({ type: FETCH_ERROR, payload: data.error });
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  }
};

export const userSignOut = () => {
  return async dispatch => {
    try {
      dispatch({ type: FETCH_START });
      const { data } = await httpRequest(
        "/logout",
        "post",
        null,
        null,
        URL_SERVER
      );

      if (data.result) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_USER_SUCCESS });
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  }
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    // axios.post('auth/me',
    // ).then(({data}) => {
    //   console.log("userSignIn: ", data);
    //   if (data.result) {
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: USER_DATA, payload: data.user});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    //   console.log("Error****:", error.message);
    // });
  }
};

export const validateToken = (callback) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    if (auth && auth.token) {
      callback(dispatch, auth.token, getState);
    } else {
      dispatch({ type: NO_TOKEN });
    }
  };
}

export function solveError(data, dispatch, type) {
  if (data && (data.name === "TokenExpiredError" || data.name === "JsonWebTokenError")) {
    console.log("Entra");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  } else {
    dispatch({ type, payload: data });
    console.log("Error****:", data);
  }
}
