import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import { useSelector } from 'react-redux';

const Routes = ({ match }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  if (authUser.rol === 'superadmin') {
    return (
      <Switch>
        <Route path={`${match.url}/home-page`}
          component={asyncComponent(() => import('./HomePage'))} />
        <Route path={`${match.url}/company-page`}
          component={asyncComponent(() => import('./CompanyPage'))} />
        <Route path={`${match.url}/user-page`}
          component={asyncComponent(() => import('./UsersPage'))} />
      </Switch>
    )
  } else if (authUser.rol === 'contact') {
    return (
      <Switch>
        <Route path={`${match.url}/reservations`}
          component={asyncComponent(() => import('./ReservationsPage'))} />
        <Route path={`${match.url}/reservation-page`}
          component={asyncComponent(() => import('./SearchEnginePage'))} />
        <Route path={`${match.url}/booking-calendar`}
          component={asyncComponent(() => import('./BookingCalendar'))} />
      </Switch>
    )
  } else if (authUser.rol === 'employee') {
    return (
      <Switch>
        <Route path={`${match.url}/home-page`}
          component={asyncComponent(() => import('./HomePage'))} />
        <Route path={`${match.url}/contact-page`}
          component={asyncComponent(() => import('./ContactPage'))} />
        <Route path={`${match.url}/reservations`}
          component={asyncComponent(() => import('./ReservationsPage'))} />
        <Route path={`${match.url}/reservation-page`}
          component={asyncComponent(() => import('./SearchEnginePage'))} />
        <Route path={`${match.url}/booking-calendar`}
          component={asyncComponent(() => import('./BookingCalendar'))} />
      </Switch>
    )
  } else {
    // default rol
    return (
      <Switch>
        <Route path={`${match.url}/home-page`}
          component={asyncComponent(() => import('./HomePage'))} />
        <Route path={`${match.url}/contact-page`}
          component={asyncComponent(() => import('./ContactPage'))} />
        <Route path={`${match.url}/employee-page`}
          component={asyncComponent(() => import('./EmployeePage'))} />
        <Route path={`${match.url}/service-page`}
          component={asyncComponent(() => import('./ServicePage'))} />
        <Route path={`${match.url}/reservation-page`}
          component={asyncComponent(() => import('./SearchEnginePage'))} />
        <Route path={`${match.url}/location-page`}
          component={asyncComponent(() => import('./LocationPage'))} />
        <Route path={`${match.url}/inventary-page`}
          component={asyncComponent(() => import('./InventaryPage'))} />
        <Route path={`${match.url}/reservations`}
          component={asyncComponent(() => import('./ReservationsPage'))} />
        <Route path={`${match.url}/sales-page`}
          component={asyncComponent(() => import('./SalesPage'))} />
        <Route path={`${match.url}/transactions`}
          component={asyncComponent(() => import('./TransactionPage'))} />
        <Route path={`${match.url}/booking-calendar`}
          component={asyncComponent(() => import('./BookingCalendar'))} />
        {/* <Route path={`${match.url}/sale-page/outflows`}
               component={asyncComponent(() => import('./SalesPage'))}/> */}
      </Switch>
    )
  }
}

export default withRouter(Routes);

