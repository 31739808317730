import React from 'react';
import LandingPage from "./LandingPage";

const HomePage = (props) => {

  return (
    <div>
      <LandingPage />
    </div>
  );
};


export default HomePage;
