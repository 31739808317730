import {CHANGE_VALUE_EMPLOYEE, CLEAR_FORM_EMPLOYEE, FETCH_EMPLOYEE_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  first_name: "",
  last_name: "",
  document: "",
  address: "",
  email: "",
  gender: "",
  phone: "",
  schedules: [],
  services: [],
  locations: [],
  password: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_EMPLOYEE:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_EMPLOYEE_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_EMPLOYEE:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
