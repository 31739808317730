import {
  CHANGE_VALUE_SALES,
  FETCH_ALL_SALES,
  CLEAR_FORM_SALES,
  SIGNOUT_USER_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  reference: "",
  price: 0.0,
  category: "",
  tax: 0.0,
  description: "",
  customer: "",
  payment_method: "",
  order_status: "",
  type: "",
  products: [],
  company: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_SALES:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_ALL_SALES:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_SALES:
      return {
        ...INIT_STATE
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
