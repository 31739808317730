import { CHANGE_ADD_NEW_TAB_TEMPLATE, CHANGE_VALUE_EMAIL_TEMPLATE, SIGNOUT_USER_SUCCESS, FETCH_ALL_EMAIL_TEMPLATES, FETCH_ALL_EMAIL_TEMPLATE_RESET } from "../../constants/ActionTypes";

const INIT_STATE = {
  records: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_EMAIL_TEMPLATES:
      let newRecord = action.payload;
      // the array newRecord is map to the array records if the field example is object parase to string
      newRecord = newRecord.map((item) => {
        if (typeof item.example === "object") {
          item.example = JSON.stringify(item.example);
        }
        return item;
      });
      return {
        ...state,
        records: newRecord
      };
    case CHANGE_VALUE_EMAIL_TEMPLATE:
      return {
        ...state,
        // motate2 the state into array from idElement
        records: state.records.map((record) => {
          if (record?._id === action.payload?.template?._id) {
            return {
              ...record,
              [action.payload.name]: action.payload.value
            };
          }
          return record;
        })
      };
    case CHANGE_ADD_NEW_TAB_TEMPLATE:
      return {
        ...state,
        records: [...state.records, ...[{
          code: action.payload.value,
          title: 'Title',
          type: 'custom',
          example: '',
          source: '',
          company: action.payload.company || ''
        }]]
      };

    case FETCH_ALL_EMAIL_TEMPLATE_RESET:
      return {
        ...INIT_STATE
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
