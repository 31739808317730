import { SIGNOUT_USER_SUCCESS, CHANGE_VALUE_RESERVATION, CLEAR_FORM_RESERVATION, FETCH_ALL_RESERVATION_SUCCESS } from "../../constants/ActionTypes";
import moment from "moment";

const INIT_STATE = {
  id: "",
  locations: [],
  services: [],
  events: [],
  identification: "",
  startHour: moment().add(5, "minutes"),
  endHour: moment().add(5, "minutes").add(1, "hours"),
  days: 0,
  hours: 0,
  price: 0,
  employes: [],
  client: {
    estado: true,
    _id: "",
    first_name: "",
    last_name: "",
    document: "",
    email: "",
    gender: "",
    username: "",
    phone: "",
    address: "",
    creation_date: ""
  }
};
const dataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_RESERVATION:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_ALL_RESERVATION_SUCCESS:
      let data = action.payload;
      if (Object.keys(data).length === 0) {
        data = { client: INIT_STATE.client }
      }
      return {
        ...state,
        ...data
      };
    case CLEAR_FORM_RESERVATION:
      return {
        ...INIT_STATE
      };
    case SIGNOUT_USER_SUCCESS:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
export default dataReducer
