import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { toastr } from "react-redux-toastr";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const custom = store => next => action => {
  const exeptions = ['@ReduxToastr']
  if (!exeptions.some(e => action && action.type && e === action.type.indexOf(e) === -1)) {
    if (action.type.indexOf('success') !== -1) {
      if (typeof action.payload === 'string') {
        toastr.info("Aviso", action.payload);
      }
    }
    if (action.type.indexOf('error') !== -1) {
      if (typeof action.payload === 'string') {
        toastr.error("Aviso", action.payload);
      }
      if (typeof action.payload === 'object') {
        const msj = action.payload.error ? action.payload.error : action.payload;
        toastr.error("Aviso", msj);
        if(msj === "jwt expired"){
          localStorage.clear();
          window.location.href = "/signin";
        }
      }
    }
  }
  return next(action)
}
export default function configureStoreApp(initialState) {
  let store = null
  if (process.env.NODE_ENV !== "production") {
    store = createStore(reducers(history), initialState, composeEnhancers(applyMiddleware(...middlewares, custom)));
  } else {
    store = createStore(reducers(history), initialState, applyMiddleware(...middlewares, custom));
  }
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
