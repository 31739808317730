import {CHANGE_VALUE_COMPANY, CLEAR_FORM_COMPANY, FETCH_COMPANY_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  name: "",
  nit: "",
  address: "",
  email: "",
  phone: "",
  status: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_COMPANY:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_COMPANY_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_COMPANY:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
