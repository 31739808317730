import {CHANGE_VALUE_USER, CLEAR_FORM_USER, FETCH_USER_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  first_name: "",
  last_name: "",
  document: "",
  email: "",
  phone: "",
  password: "",
  company: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_USER:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_USER_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_USER:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
