import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../util/IntlMessages';
import { userSignIn } from '../actions/Auth';
import { hideMessage } from "../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from '@material-ui/lab/Alert';

const SignIn = (props) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const { loading, error } = useSelector(({ commonData }) => commonData);
  
  useEffect(() => {
    if (props.message) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
    if (token !== null) {

      props.history.push('/');
    }
  }, [props.message, token, props.history, dispatch])

  return (
    <div
      className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Xicuro">
            <img src={"/images/xicuroFullLogo.png"} alt="Codytion" title="Xicuro" />
          </Link>
        </div>

        <div className="app-login-content">
          {error ?
            <Fragment>
              <Alert severity="error">{error}!</Alert><br />
            </Fragment>
            : null}

          <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.signIn" /></h1>
          </div>
          <div className="app-login-form">
            <form>
              <fieldset>
                <TextField
                  label="Email / Username"
                  fullWidth
                  onChange={(event) => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  className="mt-1 my-sm-3"
                />
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  onKeyDown={e => e.key === 'Enter' && dispatch(userSignIn({ email, password }))}
                />
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button onClick={() => {
                    dispatch(userSignIn({ email, password }));
                  }} variant="contained" color="primary">
                    <IntlMessages id="appModule.signIn" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {
        loading &&
        <div className="loader-view">
          <CircularProgress />
        </div>
      }
    </div>
  );
};


export default SignIn;
