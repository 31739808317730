import { PREVIEW_EMAIL_TEMPLATE_HTML } from "../../constants/ActionTypes";

const INIT_STATE = {
  html: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PREVIEW_EMAIL_TEMPLATE_HTML:
      const data = action.payload.html;
      return {
        ...state,
        html: data
      };
    default:
      return state;
  }
}
