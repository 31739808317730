import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,  
  URL_SERVER,
  CHANGE_VALUE_CONTACT,
  CLEAR_FORM_CONTACT,
  FETCH_ALL_CONTACT_SUCCESS,
  FETCH_CONTACT_SUCCESS,
  FETCH_START_TABLE,
  FETCH_SUCCESS_TABLE
} from "../constants/ActionTypes";
import { httpRequest } from "../util/Api";
import { validateToken, solveError } from "./Auth";

export function changeValueContact(name, value) {
  return {
    type: CHANGE_VALUE_CONTACT,
    field: name,
    value
  };
}

export function clearFormContact() {
  return {
    type: CLEAR_FORM_CONTACT
  };
}

export const searchContact = (contact) => {
  return validateToken(async (dispatch, token) => {
    try {
      dispatch({type: FETCH_START});

      const result = await httpRequest(
        "/user/" + (contact._id ? contact._id : ""),
        "post",
        token,
        null,
        URL_SERVER
      );
      if (result) {
        dispatch({type: FETCH_SUCCESS, payload: "Contacto cargado!"});
        dispatch({
          type: FETCH_CONTACT_SUCCESS,
          payload: result.data
        });
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  });
};

export const addContact = () => {
  return validateToken(async (dispatch, token, getState) => {
    try {
      dispatch({type: FETCH_START});

      const { contact } = getState();
      contact.rol = 'contact';
      const result = await httpRequest(
        "/user",
        "post",
        token,
        contact,
        URL_SERVER
      );
      if (result) {
        dispatch({type: FETCH_SUCCESS, payload: 'Contacto Agregado Exitosamente!'});
        await queryContact(dispatch, token);
        dispatch({type: CLEAR_FORM_CONTACT});
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  });
};

export const deleteContact = (contact) => {
  return validateToken(async (dispatch, token) => {
    const areYouSure = window.confirm("Esta seguro?");
    if (areYouSure) {
      try {
        dispatch({type: FETCH_START_TABLE});

        const result = await httpRequest(
          "/user",
          "delete",
          token,
          contact,
          URL_SERVER
        );
        if (result) {
          dispatch({type: FETCH_SUCCESS_TABLE});
          dispatch({type: CLEAR_FORM_CONTACT});
          await queryContact(dispatch, token);
        }
      } catch (error) {
        solveError(
          error?.response?.data?.error || error.message ||'',
          dispatch,
          FETCH_ERROR
        );
      }
    }
  });
};

export const editContact = () => {
  return validateToken(async (dispatch, token, getState) => {
    try {
      dispatch({type: FETCH_START});

      const { contact } = getState();
      const result = await httpRequest(
        "/user",
        "put",
        token,
        contact,
        URL_SERVER
      );
      if (result) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: CLEAR_FORM_CONTACT});
        await queryContact(dispatch, token);
      }
    } catch (error) {
      solveError(
        error?.response?.data?.error || error.message ||'',
        dispatch,
        FETCH_ERROR
      );
    }
  });
};

export const loadListContacts = () => {
  return validateToken(async (dispatch, token) => {
    await queryContact(dispatch, token);      
  });
};

async function queryContact(dispatch, token) {
  try {
    dispatch({type: FETCH_START_TABLE});

    const {data} = await httpRequest(
      "/user/contact",
      "get",
      token,
      null,
      URL_SERVER
    );
    if (data) {
      dispatch({type: FETCH_SUCCESS_TABLE, payload: "Contactos cargados!"});
      dispatch({
        type: FETCH_ALL_CONTACT_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    solveError(
      error?.response?.data?.error || error.message ||'',
      dispatch,
      FETCH_ERROR
    );
  }
}

