import {CHANGE_VALUE_LOCATION, CLEAR_FORM_LOCATION, FETCH_LOCATION_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  name: "",
  city: "",
  address: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_LOCATION:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_LOCATION_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_LOCATION:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
