import { CHANGE_VALUE_TAB_TEMPLATE, CLEAR_FORM_CHANGE_VALUE_EMAIL_TEMPLATE } from "../../constants/ActionTypes";

const INIT_STATE = {
  indexTab: "",
  acordeonSource: false,
  acordeonPayload: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_TAB_TEMPLATE:
      const { field, value } = action.payload;
      return {
        ...state,
        [field]: value
      };
    case CLEAR_FORM_CHANGE_VALUE_EMAIL_TEMPLATE:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
