import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'
import configureStoreApp, { history } from './store';
// import './firebase/firebase';
import App from './containers/App';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './index.css';
export const store = configureStoreApp();

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick />
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
  </Provider>;

export default MainApp;