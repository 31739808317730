import {FETCH_DASHBOARD} from '../constants/ActionTypes'

const INIT_STATE = {
  totalEmployes: 0,
  totalContacts: 0,
  totalReservations: 0,
  contacts: [],
  reservations: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD: {
      const data = action.payload;
      return {
        ...data
      };
    }
    default:
      return state;
  }
}
