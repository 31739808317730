// se genera version dinamica para IOS, Linux y Windows
// export const URL_SERVER = window.location.origin.split(window.location.port)[0] + "3032/api";
// export const URL_SERVER = "https://codytion.com/api";
 export const URL_SERVER = window.location.origin + "/api";
// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme'; 
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START_TABLE = 'fetch_start_table';
export const FETCH_SUCCESS_TABLE = 'fetch_success_table';
export const FETCH_DASHBOARD = 'fetch_dashboard';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const FETCH_ALL_CONTACT_RESET = 'fetch_all_contact_reset';
export const FETCH_CONTACT_SUCCESS = 'fetch_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const CHANGE_VALUE_CONTACT = 'change_value_contact';
export const CLEAR_FORM_CONTACT = 'clear_form_contact';

//Employee const
export const FETCH_ALL_EMPLOYEE = 'fetch_all_employee';
export const FETCH_ALL_EMPLOYEE_SUCCESS = 'fetch_all_employee_success';
export const FETCH_SEARCH_EMPLOYEE_SUCCESS = 'fetch_search_employee_success';
export const FETCH_ALL_EMPLOYEE_RESET = 'fetch_all_employee_reset';
export const FETCH_EMPLOYEE_SUCCESS = 'fetch_employee_success';
export const CHANGE_VALUE_EMPLOYEE = 'change_value_employee';
export const CLEAR_FORM_EMPLOYEE = 'clear_form_employee';
export const FETCH_CANCEL_RESERVATION = 'fetch_cancel_reservation';

//User const
export const FETCH_ALL_USER_SUCCESS = 'fetch_all_user_success';
export const FETCH_ALL_USER_RESET = 'fetch_all_user_reset';
export const FETCH_USER_SUCCESS = 'fetch_user_success';
export const CHANGE_VALUE_USER = 'change_value_user';
export const CLEAR_FORM_USER = 'clear_form_user';

//Company const
export const FETCH_ALL_COMPANY_SUCCESS = 'fetch_all_company_success';
export const FETCH_ALL_COMPANY_RESET = 'fetch_all_company_reset';
export const FETCH_COMPANY_SUCCESS = 'fetch_company_success';
export const CHANGE_VALUE_COMPANY = 'change_value_company';
export const CLEAR_FORM_COMPANY = 'clear_form_company';

//Company const
export const CHANGE_VALUE_EMAIL_TEMPLATE = 'change_value_email_template';
export const CLEAR_FORM_EMAIL_TEMPLATE = 'clear_form_email_template';
export const FETCH_ALL_EMAIL_TEMPLATES = 'fetch_all_email_templates';
export const FETCH_ALL_EMAIL_TEMPLATE_RESET = 'fetch_all_email_template_reset';
export const FETCH_EMAIL_TEMPLATE_SUCCESS = 'fetch_email_template_success';
export const FETCH_SELECT_EMAIL_TEMPLATE_SUCCESS = 'fetch_select_email_template_success';
export const FETCH_CHANGE_VALUE_TEMPLATE_SUCCESS = 'fetch_change_value_template_success';   
export const CLEAR_FORM_CHANGE_VALUE_EMAIL_TEMPLATE = 'clear_form_change_value_email_template';
export const PREVIEW_EMAIL_TEMPLATE = 'preview_email_template';
export const PREVIEW_EMAIL_TEMPLATE_HTML = 'preview_email_template_html';
export const CHANGE_ADD_NEW_TAB_TEMPLATE = 'change_add_new_tab_template';
export const CHANGE_VALUE_TAB_TEMPLATE = 'change_value_tab_template';


//Services const
export const FETCH_ALL_SERVICE = 'fetch_all_service';
export const FETCH_ALL_SERVICE_SUCCESS = 'fetch_all_service_success';
export const FETCH_ALL_SERVICE_RESET = 'fetch_all_service_reset';
export const FETCH_SERVICE_SUCCESS = 'fetch_service_success';
export const CHANGE_VALUE_SERVICE = 'change_value_service';
export const CLEAR_FORM_SERVICE = 'clear_form_service';

//Locations const
export const FETCH_ALL_LOCATION = 'fetch_all_location';
export const FETCH_ALL_LOCATION_SUCCESS = 'fetch_all_location_success';
export const FETCH_ALL_LOCATION_RESET = 'fetch_all_location_reset';
export const FETCH_SELECT_LOCATION_SUCCESS = 'fetch_select_location_success';
export const FETCH_SELECT_LOCATION_RESET = 'fetch_select_location_reset';
export const FETCH_LOCATION_SUCCESS = 'fetch_location_success';
export const CHANGE_VALUE_LOCATION = 'change_value_location';
export const CLEAR_FORM_LOCATION = 'clear_form_location';
//inventary const
export const FETCH_ALL_INVENTARY = 'fetch_all_inventary';
export const FETCH_ALL_INVENTARY_SUCCESS = 'fetch_all_inventary_success';
export const FETCH_ALL_INVENTARY_RESET = 'fetch_all_inventary_reset';
export const FETCH_SELECT_INVENTARY_SUCCESS = 'fetch_select_inventary_success';
export const FETCH_SELECT_INVENTARY_RESET = 'fetch_select_inventary_reset';
export const FETCH_INVENTARY_SUCCESS = 'fetch_inventary_success';
export const CHANGE_VALUE_INVENTARY = 'change_value_inventary';
export const CLEAR_FORM_INVENTARY = 'clear_form_inventary';
//sales const
export const FETCH_ALL_SALES = 'fetch_all_sales';
export const FETCH_ALL_SALES_SUCCESS = 'fetch_all_sales_success';
export const FETCH_ALL_SALES_RESET = 'fetch_all_sales_reset';
export const FETCH_SELECT_SALES_SUCCESS = 'fetch_select_sales_success';
export const FETCH_SELECT_SALES_RESET = 'fetch_select_sales_reset';
export const FETCH_SALES_SUCCESS = 'fetch_sales_success';
export const CHANGE_VALUE_SALES = 'change_value_sales';
export const CLEAR_FORM_SALES = 'clear_form_sales';
// files const
export const FETCH_ALL_FILES = 'fetch_all_files';
export const FETCH_ALL_FILES_SUCCESS = 'fetch_all_files_success';
export const FETCH_ALL_FILES_RESET = 'fetch_all_files_reset';
export const FETCH_SELECT_FILES_SUCCESS = 'fetch_select_files_success';
export const FETCH_SELECT_FILES_RESET = 'fetch_select_files_reset';
export const FETCH_FILES_SUCCESS = 'fetch_files_success';
export const CHANGE_VALUE_FILES = 'change_value_files';
export const CLEAR_FORM_FILES = 'clear_form_files';
export const CLEAR_FORM_FILE_VALUE_FILES = 'clear_form_file_value_files';
export const FETCH_ALL_FILE_VALUE_FILES_SUCCESS = 'fetch_all_file_value_files_success';
export const FETCH_FILE_VALUE_FILES_SUCCESS = 'fetch_file_value_files_success';
export const FETCH_SELECT_FILE_VALUE_FILES_SUCCESS = 'fetch_select_file_value_files_success';

//Locations const
export const FETCH_ALL_NEWS = 'fetch_all_news';
export const FETCH_ALL_NEWS_SUCCESS = 'fetch_all_news_success';
export const FETCH_ALL_NEWS_RESET = 'fetch_all_news_reset';
export const FETCH_SELECT_NEWS_SUCCESS = 'fetch_select_news_success';
export const FETCH_SELECT_NEWS_RESET = 'fetch_select_news_reset';
export const FETCH_NEWS_SUCCESS = 'fetch_news_success';
export const CHANGE_VALUE_NEWS = 'change_value_news';
export const CLEAR_FORM_NEWS = 'clear_form_news';

//Reservation const
export const FETCH_ALL_RESERVATION = 'fetch_all_reservation';
export const FETCH_ALL_RESERVATION_SUCCESS = 'fetch_all_reservation_success';
export const FETCH_LIST_RESERVATIONS_SUCCESS = 'fetch_list_reservations_success';
export const FETCH_ALL_RESERVATION_RESET = 'fetch_all_reservation_reset';
export const FETCH_RESERVATION_SUCCESS = 'fetch_reservation_success';
export const CHANGE_VALUE_RESERVATION = 'change_value_reservation';
export const CLEAR_FORM_RESERVATION = 'clear_form_reservation';
export const CREATE_RESERVATION_SUCCESS = 'create_reservation_success';
export const SET_ALL_EVENTS_SUCCESS = 'set_all_events_success';
export const FETCH_START_EVENTS = 'fetch_start_events';
export const FETCH_LIST_EVENTS_SUCCESS = 'fetch_list_events_success';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const NO_TOKEN = 'no_token';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Treansactions Module
export const FETCH_ALL_TRANSACTION_SUCCESS = 'fetch_all_transaction_success';
//// Mail Module
export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';