import {CHANGE_VALUE_SERVICE, CLEAR_FORM_SERVICE, FETCH_SERVICE_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  id: "",
  name: "",
  price: 0,
  duration: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_VALUE_SERVICE:
      return {
        ...state,
        [action.field]: action.value
      };
    case FETCH_SERVICE_SUCCESS:
      const data = action.payload;
      return {
        ...data
      };
    case CLEAR_FORM_SERVICE:
      return {
        ...INIT_STATE
      };
    default:
      return state;
  }
}
