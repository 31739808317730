import React from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "../../../../constants/ActionTypes";
import {toggleCollapsedNav} from "../../../../actions/Setting";

const Index = (props) => {

  const dispatch = useDispatch();
  const {drawerType} = useSelector(({settings}) => settings);

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>

        <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                    onClick={onToggleCollapsedNav}>
          <span className="menu-icon"/>
        </IconButton>

        <Link className="app-logo mr-2 d-none d-sm-block ml-auto" to="/">
          <img src={"/images/logoXicuro.png"} alt="Codytion" title="Xicuro"/>
        </Link>

        {/* <ul className="header-notifications list-inline ml-auto">
          <li className="d-inline-block d-lg-none list-inline-item">
            <Dropdown
              className="quick-menu nav-searchbox"
              isOpen={searchBox}
              toggle={onSearchBoxSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className="zmdi zmdi-search zmdi-hc-fw"/>
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="p-0">
                <SearchBox styleName="search-dropdown" placeholder=""
                           onChange={updateSearchText}
                           value={searchText}/>
              </DropdownMenu>
            </Dropdown>
          </li>
          <li className="list-inline-item">
            <Dropdown
              className="quick-menu"
              isOpen={langSwitcher}
              toggle={onLangSwitcherSelect}>

              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <IconButton className="icon-btn">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                </IconButton>
              </DropdownToggle>

              <DropdownMenu right className="w-50">
                <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                  handleRequestClose={handleRequestClose}/>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul> */}
        <div className="ellipse-shape"/>
      </Toolbar>
    </AppBar>
  );
};


export default withRouter(Index);
